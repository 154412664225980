<template>
  <footer ref="BottomNav" class="bottom-nav transparent">
    <div class="bottom-nav-container flex" data-nosnippet>
      <div class="bottom-nav-container-isotype flex">
        <div ref="IsotypeWrapper" class="bottom-nav-container-isotype-wrapper transparent">
          <a
            :href="$utils.getPageRoute()"
            :title="applicationName"
            class="bottom-nav-container-isotype-wrapper-cta"
          ></a>
          <object
            v-if="$store.state.Global.online"
            :data="isotype"
            height="100%"
            width="100%"
            ref="Isotype"
            type="image/svg+xml"
          ></object>
          <img
            v-else
            ref="IsotypePng"
            src="/img/logotype/onca-vega-isotipo-120.png"
            alt="Onca Vega Logo"
            height="100%"
            width="100%"
          />
        </div>
      </div>
      <div class="bottom-nav-container-routes internal">
        <div ref="InternalRoutesWrapper" class="bottom-nav-container-routes-wrapper">
          <a
            v-for="(route, i) in routes.internal"
            :href="$utils.getPageRoute(route.href)"
            :title="$t(route.description)"
            :ref="`BottomNavInternalRoute-${i}`"
            :key="`BottomNavInternalRoute-${i}`"
            :class="[
              'general-anchor decorated bottom-nav-container-routes-wrapper-cta transparent',
              { active: $utils.routeIsActive(route.href) }
            ]"
          >
            <span>
              <font-awesome-icon :icon="route.icon" />
              {{ $t(route.description) }}
            </span>
          </a>
        </div>
      </div>
      <div class="bottom-nav-container-routes">
        <div ref="ExternalRoutesWrapper" class="bottom-nav-container-routes-wrapper">
          <a
            v-for="(route, i) in routes.external"
            :href="route.href"
            :title="route.description"
            :ref="`BottomNavExternalRoute-${i}`"
            :key="`BottomNavExternalRoute-${i}`"
            class="general-anchor decorated bottom-nav-container-routes-wrapper-cta transparent"
            rel="noreferrer,noopener"
            target="_blank"
          >
            <span>
              <font-awesome-icon :icon="route.icon" />
              {{ route.description }}
            </span>
          </a>
        </div>
      </div>
      <div class="bottom-nav-container-advice flex">
        <div ref="AdviceWrapper" class="text-center transparent">
          <a
            :href="$utils.getPageRoute('/aviso-de-privacidad/')"
            :title="$t('navigation.privacyPolicy')"
            :class="[
              'general-anchor decorated',
              { active: $utils.routeIsActive('/aviso-de-privacidad/') }
            ]"
          >
            <span>{{ $t("navigation.privacyPolicy") }}</span>
          </a>
          |
          <a
            :href="$utils.getPageRoute('/terminos-y-condiciones/')"
            :title="$t('navigation.termsAndConditions')"
            :class="[
              'general-anchor decorated',
              { active: $utils.routeIsActive('/terminos-y-condiciones/') }
            ]"
          >
            <span>{{ $t("navigation.termsAndConditions") }}</span>
          </a>
          |
          <span>
            &reg; <span class="text-white-purple">{{ applicationName }}</span>
            {{ $t("bottomNav.advice") }}
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import isotype from "@/assets/images/isotype/onca-vega-isotipo-120.svg";

export default {
  name: "BottomNav",
  data() {
    return {
      isotype
    };
  },
  computed: {
    /*
     * @computed - routes
     * Gets routes from utils plugin
     */
    routes() {
      return this.$utils.routes;
    },
    /*
     * @computed - applicationName
     * Gets name of the application
     */
    applicationName() {
      return this.$store.state.Global.env.APPLICATION_NAME;
    }
  },
  methods: {
    /*
     * @function - animateEntry
     * Calls bottomnav fade and isotype animation
     * @param - none
     */
    animateEntry() {
      const {
        BottomNav,
        IsotypeWrapper,
        InternalRoutesWrapper,
        ExternalRoutesWrapper,
        AdviceWrapper
      } = this.$refs;
      const { medium } = this.$utils.styles.breakpoints;

      this.m_animateEntry([
        {
          component: BottomNav,
          method: "animateComponent",
          operationBreakpoint: "LARGEROREQUAL",
          behavior: "middle",
          boundary: medium
        },
        {
          component: BottomNav,
          method: "animateBottomNav",
          operationBreakpoint: "SHORTER",
          behavior: "top",
          boundary: medium
        },
        {
          component: IsotypeWrapper,
          method: "animateIsotype",
          operationBreakpoint: "SHORTER",
          behavior: "bottom",
          boundary: medium
        },
        {
          component: InternalRoutesWrapper,
          method: "animateInternalRoutes",
          operationBreakpoint: "SHORTER",
          behavior: "bottom",
          boundary: medium
        },
        {
          component: ExternalRoutesWrapper,
          method: "animateExternalRoutes",
          operationBreakpoint: "SHORTER",
          behavior: "bottom",
          boundary: medium
        },
        {
          component: AdviceWrapper,
          method: "animateAdvice",
          operationBreakpoint: "SHORTER",
          behavior: "top",
          boundary: medium
        }
      ]);
    },
    /*
     * @function - animateComponent
     * Calls BottomNav elements fade and translate stepped animation
     * @param - none
     */
    animateComponent() {
      this.animateBottomNav();
      this.animateIsotype();
      this.animateInternalRoutes();
      this.animateExternalRoutes(500);
      this.animateAdvice(1500);
    },
    /*
     * @function - animateBottomNav
     * Animates BottomNav in fade mode
     * @param - none
     */
    animateBottomNav() {
      this.m_animateFade(this.$refs.BottomNav, 1000, "in");
    },
    /*
     * @function - animateIsotypePng
     * Calls isotype png animation
     * @param - none
     */
    animateIsotypePng() {
      const { IsotypePng } = this.$refs;

      this.m_animateFade(IsotypePng, 575, "in")
        .then(() => this.m_animateFade(IsotypePng, 575))
        .then(() => this.m_animateFade(IsotypePng, 575, "in"))
        .then(() => this.m_animateFade(IsotypePng, 575))
        .then(() => this.m_animateFade(IsotypePng, 575, "in"));
    },
    /*
     * @function - animateIsotype
     * Calls isotype element animation
     * @param - none
     */
    animateIsotype() {
      const { IsotypeWrapper, Isotype } = this.$refs;

      this.m_animateFade(IsotypeWrapper, 500, "in");
      this.$store.state.Global.online
        ? this.m_animateIsotype(Isotype.contentDocument)
        : this.animateIsotypePng();
    },
    /*
     * @function - animateInternalRoutes
     * Animates internal routes fade and translate stepped animation
     * @param - externalDelay (Number): Defines delay in milliseconds for animation
     */
    animateInternalRoutes(externalDelay = 0) {
      const delay = i => externalDelay + 500 + 100 * i;

      this.routes.internal.forEach((route, i) => {
        const routeEl = this.$refs[`BottomNavInternalRoute-${i}`][0];

        this.m_animateTranslation(routeEl, 300, 100, 0, 0, 0, delay(i), false);
        this.m_animateFade(routeEl, 500, "in", delay(i));
      });
    },
    /*
     * @function - animateExternalRoutes
     * Animates external routes fade and translate stepped animation
     * @param - externalDelay (Number): Defines delay in milliseconds for animation
     */
    animateExternalRoutes(externalDelay = 0) {
      const delay = i => externalDelay + 500 + 100 * i;

      this.routes.external.forEach((route, i) => {
        const routeEl = this.$refs[`BottomNavExternalRoute-${i}`][0];

        this.m_animateTranslation(routeEl, 300, -100, 0, 0, 0, delay(i), false);
        this.m_animateFade(routeEl, 500, "in", delay(i));
      });
    },
    /*
     * @function - animateAdvice
     * Animates advice fade stepped animation
     * @param - externalDelay (Number): Defines delay in milliseconds for animation
     */
    animateAdvice(externalDelay = 0) {
      this.m_animateFade(this.$refs.AdviceWrapper, 1000, "in", externalDelay);
    }
  }
};
</script>

<style lang="scss" scoped>
.bottom-nav {
  @include boxShadow(0.35, false, true);
  background-color: $white-gray;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 27rem;
  z-index: 700;

  @include breakpoint($medium) {
    height: 22rem;
  }

  &-container {
    flex-wrap: wrap;
    height: 100%;

    &-isotype {
      flex: 0 1 100%;
      height: 30%;
      order: 2;

      @include breakpoint($medium) {
        flex: 0 1 15%;
        height: 90%;
        order: 1;
      }

      &-wrapper {
        margin: auto;
        height: 4rem;
        position: relative;

        @include breakpoint($medium) {
          height: 5rem;
        }

        @include breakpoint($large) {
          height: 6rem;
        }

        @include breakpoint($xxxxlarge) {
          width: 10rem;
        }

        @include breakpoint($xxxxxlarge) {
          width: 12rem;
        }

        &-cta {
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    &-routes {
      flex: 0 1 50%;
      height: 50%;
      order: 1;

      @include breakpoint($small) {
        border-top: none;
        height: 55%;
        margin-top: 0;
      }

      @include breakpoint($medium) {
        flex: 0 1 42.5%;
        height: 90%;
        order: 2;
      }

      &.internal {
        border-right: 0.0625rem solid $main-green;
        margin-right: -0.0625rem;

        @include breakpoint($medium) {
          border-left: 0.0625rem solid $main-green;
          margin-left: -0.0625rem;
        }
      }

      &-wrapper {
        display: table;
        margin: auto;

        @include breakpoint($medium) {
          margin: 0.75rem auto;
        }

        &-cta {
          display: block;
          margin: 1rem 0;
        }
      }
    }

    &-advice {
      align-items: center;
      border-top: 0.0625rem solid $main-green;
      flex: 0 1 100%;
      justify-content: center;
      height: 20%;
      margin-top: -0.0625rem;
      order: 3;

      @include breakpoint($small) {
        height: 15%;
      }

      @include breakpoint($medium) {
        border-top: none;
        height: 10%;
        margin-top: 0;
      }
    }
  }
}
</style>
