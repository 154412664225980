var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{ref:"BottomNav",staticClass:"bottom-nav transparent"},[_c('div',{staticClass:"bottom-nav-container flex",attrs:{"data-nosnippet":""}},[_c('div',{staticClass:"bottom-nav-container-isotype flex"},[_c('div',{ref:"IsotypeWrapper",staticClass:"bottom-nav-container-isotype-wrapper transparent"},[_c('a',{staticClass:"bottom-nav-container-isotype-wrapper-cta",attrs:{"href":_vm.$utils.getPageRoute(),"title":_vm.applicationName}}),(_vm.$store.state.Global.online)?_c('object',{ref:"Isotype",attrs:{"data":_vm.isotype,"height":"100%","width":"100%","type":"image/svg+xml"}}):_c('img',{ref:"IsotypePng",attrs:{"src":"/img/logotype/onca-vega-isotipo-120.png","alt":"Onca Vega Logo","height":"100%","width":"100%"}})])]),_c('div',{staticClass:"bottom-nav-container-routes internal"},[_c('div',{ref:"InternalRoutesWrapper",staticClass:"bottom-nav-container-routes-wrapper"},_vm._l((_vm.routes.internal),function(route,i){return _c('a',{key:("BottomNavInternalRoute-" + i),ref:("BottomNavInternalRoute-" + i),refInFor:true,class:[
            'general-anchor decorated bottom-nav-container-routes-wrapper-cta transparent',
            { active: _vm.$utils.routeIsActive(route.href) }
          ],attrs:{"href":_vm.$utils.getPageRoute(route.href),"title":_vm.$t(route.description)}},[_c('span',[_c('font-awesome-icon',{attrs:{"icon":route.icon}}),_vm._v(" "+_vm._s(_vm.$t(route.description))+" ")],1)])}),0)]),_c('div',{staticClass:"bottom-nav-container-routes"},[_c('div',{ref:"ExternalRoutesWrapper",staticClass:"bottom-nav-container-routes-wrapper"},_vm._l((_vm.routes.external),function(route,i){return _c('a',{key:("BottomNavExternalRoute-" + i),ref:("BottomNavExternalRoute-" + i),refInFor:true,staticClass:"general-anchor decorated bottom-nav-container-routes-wrapper-cta transparent",attrs:{"href":route.href,"title":route.description,"rel":"noreferrer,noopener","target":"_blank"}},[_c('span',[_c('font-awesome-icon',{attrs:{"icon":route.icon}}),_vm._v(" "+_vm._s(route.description)+" ")],1)])}),0)]),_c('div',{staticClass:"bottom-nav-container-advice flex"},[_c('div',{ref:"AdviceWrapper",staticClass:"text-center transparent"},[_c('a',{class:[
            'general-anchor decorated',
            { active: _vm.$utils.routeIsActive('/aviso-de-privacidad/') }
          ],attrs:{"href":_vm.$utils.getPageRoute('/aviso-de-privacidad/'),"title":_vm.$t('navigation.privacyPolicy')}},[_c('span',[_vm._v(_vm._s(_vm.$t("navigation.privacyPolicy")))])]),_vm._v(" | "),_c('a',{class:[
            'general-anchor decorated',
            { active: _vm.$utils.routeIsActive('/terminos-y-condiciones/') }
          ],attrs:{"href":_vm.$utils.getPageRoute('/terminos-y-condiciones/'),"title":_vm.$t('navigation.termsAndConditions')}},[_c('span',[_vm._v(_vm._s(_vm.$t("navigation.termsAndConditions")))])]),_vm._v(" | "),_c('span',[_vm._v(" ® "),_c('span',{staticClass:"text-white-purple"},[_vm._v(_vm._s(_vm.applicationName))]),_vm._v(" "+_vm._s(_vm.$t("bottomNav.advice"))+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }